<template>
  <v-container>
    <StoneHeader/>
    <v-container>
      <h2>Not Found</h2>
    </v-container>
    <StoneFooter/>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import StoneHeader from '@/components/organisms/StoneHeader'
import StoneFooter from '@/components/organisms/StoneFooter'

export default {
  name: 'NotFound',

  components: {
    StoneHeader,
    StoneFooter
  }

}
</script>

<style>
</style>
